/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import views from './views'

// // Array of sections
// export default [...views]

export default [
  {
    title: 'start',
    route: 'start',
    icon: 'PlayCircleIcon',
    resource: 'berater',
    action: 'read',
  },
  {
    title: 'mandat',
    icon: 'FolderIcon',
    resource: 'klient',
    action: 'read',
    children: [
      {
        title: 'grunddaten',
        route: 'grunddaten',
        resource: 'berater',
        action: 'read',
      },
      {
        title: 'vorsorge',
        route: 'vorsorge',
        resource: 'beraterbvg',
        action: 'read',
      },
      {
        title: 'gueterrecht',
        route: 'gueterrecht',
        resource: 'beraterga',
        action: 'read',
      },
      {
        title: 'unterhalt',
        icon: 'ActivityIcon',
        resource: 'berateruh',
        action: 'read',
        children: [
          {
            title: 'mittel',
            route: 'mittel',
            resource: 'berateruh',
            action: 'read',
          },
          {
            title: 'bedarf',
            route: 'bedarf',
            resource: 'berateruh',
            action: 'read',
          },
          {
            title: 'uberechnung',
            route: 'unterhalt',
            resource: 'berateruh',
            action: 'read',
          },
        ],
      },
      {
        title: 'vereinbarung',
        route: 'vereinbarung',
        resource: 'berater',
        action: 'read',
      },
      {
        title: 'korrespondenz',
        route: 'korrespondenz',
        resource: 'beratererw',
        action: 'read',
      },
      {
        title: 'fragebogen',
        route: 'fragebogen',
        resource: 'klienterw',
        action: 'read',
      },
      {
        title: 'belege',
        route: 'belege',
        resource: 'klienterw',
        action: 'read',
      },
      {
        title: 'protokoll',
        route: 'protokoll',
        resource: 'beratererw',
        action: 'read',
      },
      {
        title: 'honorar',
        route: 'honorar',
        resource: 'beratererw',
        action: 'read',
      },
      {
        title: 'justitia',
        route: 'justitia',
        resource: 'berater',
        action: 'read',
      },
    ],
  },
  {
    title: 'verwaltung',
    icon: 'ToolIcon',
    children: [
      {
        title: 'mandant',
        route: 'mandant',
        resource: 'mandant',
        action: 'read',
      },
      {
        title: 'abrechnung',
        route: 'abrechnung',
        resource: 'mandant',
        action: 'read',
      },
    ],
  },
  {
    title: 'admin',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'adminabrechnung',
        route: 'adminabrechnung',
        resource: 'admin',
        action: 'read',
      },
      {
        title: 'vorlagen',
        route: 'vorlagen',
        resource: 'admin',
        action: 'read',
      },
      {
        title: 'uebersetzung',
        route: 'uebersetzung',
        resource: 'admin',
        action: 'read',
      },
    ],
  },
  {
    title: 'kalender',
    route: 'kalender',
    icon: 'CalendarIcon',
    resource: 'berater',
    action: 'read',
  },
  {
    title: 'chatbot',
    route: 'chatbot',
    icon: 'TerminalIcon',
    resource: 'berater',
    action: 'read',
  },
  {
    title: 'benutzer',
    route: 'benutzer',
    icon: 'UserIcon',
    resource: 'klient',
    action: 'read',
  },
]
